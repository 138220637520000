import { lazy } from "react"

// Dashboard
const LandingPage = lazy(() => import("pages/LandingPages"))
const AllPages = lazy(() => import("pages/LandingPages/AllPages"))
const SearchPage = lazy(() => import("components/ecommerce/search/index"))
const SingleCollection = lazy(() =>
  import("pages/custom/collection/SingleCollection")
)
const CheckoutLogin = lazy(() => import("pages/checkout/checkoutLogin"))
const AllColllection = lazy(() => import("pages/custom/collection/collections"))
const OrderDetails = lazy(() => import("pages/user/order/orderDetails"))
const AllCategoryList = lazy(() => import("pages/category"))
const CategorySingle = lazy(() => import("pages/category/SinglePage"))
const OrderSuccess = lazy(() => import("pages/checkout/OrderSuccess"))
const Checkout = lazy(() => import("pages/checkout/checkout"))
const StaticPage = lazy(() => import("pages/static/StaticPage"))
// const About = lazy(() => import("pages/static/page-about"))
const WishlistModal = lazy(() => import("pages/shop/shop-wishlist"))
const Contact = lazy(() => import("pages/user/page-contact"))
const Account = lazy(() => import("pages/user/page-account"))
const ProductsList = lazy(() => import("pages/shop/shop-list-left"))
const Cart = lazy(() => import("pages/shop/shop-cart"))
const ProductSingleView = lazy(() => import("pages/product"))
const Home = lazy(() => import("pages/Home"))
const Pages404 = lazy(() => import("pages/404/pages-404"))
const Pages500 = lazy(() => import("pages/404/pages-500"))
const Pages502 = lazy(() => import("pages/404/paymentFail"))
const Pages503 = lazy(() => import("pages/404/paymentComplete"))
const AllBlogList = lazy(() => import("pages/Blog"))
const SingleBlog = lazy(() => import("pages/Blog/SingleBlogPage"))

const publicRoutes = [
  //defualt routes
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/", component: Home },

  //main routes
  // { path: "/custom/:type", component: CustomGrid },
  // { path: "/custom/:type/:name", component: CustomGrid },

  { path: "/products/:name", component: ProductSingleView },
  { path: "/categories", component: AllCategoryList },
  { path: "/category/:name", component: CategorySingle },
  { path: "/products", component: ProductsList },
  { path: "/collections", component: AllColllection },
  { path: "/collections/:name", component: SingleCollection }, //pending

  //static routes
  { path: "/contact", component: Contact },
  // { path: "/about", component: About },

  { path: "/privacy-policy", component: StaticPage },
  { path: "/return-policy", component: StaticPage },
  { path: "/terms", component: StaticPage },

  { path: "/cart", component: Cart },
  { path: "/wishlist", component: WishlistModal },
  { path: "/search", component: SearchPage },
  // { path: "/quick-purchase", component: shopQuickPurchase },

  //blog
  // { path: "/blog/:name", component: SingleBlog },
  // { path: "/blogs", component: AllBlogList },

  //pages
  { path: "/pages", component: AllPages },
  { path: "/pages/:name", component: LandingPage },

  { path: "/checkout/login", component: CheckoutLogin },
]

const AuthProtectedRoute = [
  { path: "/user-account", component: Account },
  { path: "/user/order/:id", component: OrderDetails },
  { path: "/checkout", component: Checkout },

  { path: "/order-complete/:id", component: Pages503 },
  { path: "/order-success", component: OrderSuccess },

  { path: "/online-order-fail", component: Pages502 },
]

export { publicRoutes, AuthProtectedRoute }
