/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCTS DETAIL*/
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

// get product by category
export const GET_PRODUCT_BY_CATEGORY = "GET_PRODUCT_BY_CATEGORY"
export const GET_PRODUCT_BY_CATEGORY_SUCCESS = "GET_PRODUCT_BY_CATEGORY_SUCCESS"
export const GET_PRODUCT_BY_CATEGORY_FAIL = "GET_PRODUCT_BY_CATEGORY_FAIL"

//related product
export const GET_RELATED_PRODUCT = "GET_RELATED_PRODUCT"
export const GET_RELATED_PRODUCT_SUCCESS = "GET_RELATED_PRODUCT_SUCCESS"
export const GET_RELATED_PRODUCT_FAIL = "GET_RELATED_PRODUCT_FAIL"

//user produc review
export const ADD_PRODUCT_REVIEW = "ADD_PRODUCT_REVIEW"
export const ADD_PRODUCT_REVIEW_SUCCESS = "ADD_PRODUCT_REVIEW_SUCCESS"
export const ADD_PRODUCT_REVIEW_FAIL = "ADD_PRODUCT_REVIEW_FAIL"
